<template>
  <div class="oreder__bottom">
    <ul v-if="orderById" class="oreder__bottom-items">
      <li class="oreder__bottom-item order__slider">
        <p>
          <button class="btn mb-3 main-btn" 
          v-if="isHistoryLoaded && orderById.transport"
          :class="[eventsToggle ? 'text-dark bg-info' : '' ]" 
          @click="$emit('toggleRouteEvents')">
          {{ eventsToggle ? 'Скрыть события' : 'Показать события' }}
        </button>
        </p>
        <label
          v-if="isHistoryLoaded && orderById.transport"
          for="input-slider"
          class="order__slider-label order__slider-label-top"
        >
          <span class="order__slider-start-date">
            От: {{ formatDate(historyPoints[0].created_at) }}
          </span>
          <span class="order__slider-end-date">
            До: {{ formatDate(historyPoints[historyPoints.length - 1].created_at) }}
          </span>
        </label>
        <label
          v-else-if="!isHistoryLoaded"
          for="input-slider"
          class="order__slider-label order__slider-label-top"
          >Истории маршрута нет</label
        >
        <label v-else for="input-slider" class="order__slider-label order__slider-label-top"
          >Данных о ТС нет</label
        >
        <template v-if="isHistoryLoaded && orderById.transport">
          <input
            id="input-slider"
            v-model="sliderValue"
            class="order__slider-input"
            type="range"
            name="input-slider"
            min="0"
            :max="sliderMax"
          />
        </template>
        <input
          v-else
          id="input-slider"
          type="range"
          name="input-slider"
          min="0"
          max="1"
          class="order__slider-input order__slider-input--disabled"
          disabled
        />
        <label
          v-if="isHistoryLoaded && orderById.transport"
          for="input-slider"
          class="order__slider-label"
        >
          <span class="order__slider-index"> #{{ sliderValue }} </span>
          <span class="order__slider-date"> Выбрано: {{ sliderDate }} </span>
          <span class="order__slider-speed">
            Скорость:
            {{ sliderSpeed }}
          </span>
        </label>
        <label v-else for="input-slider" class="order__slider-label">
          <span class="order__slider-index"> #- </span>
          <span class="order__slider-date"> Выбрано: --.--.----, --:--:-- </span>
          <span class="order__slider-speed"> Скорость: - </span>
        </label>
      </li>
      <li class="oreder__bottom-item">
        <p class="oreder__bottom-name">Водитель</p>
        <h3 class="oreder__bottom-value">
          {{
            orderById.driver_status && orderById.driver_status.length > 0
              ? orderById.driver_status[0].user_name
              : "-"
          }}
        </h3>
      </li>
      <li class="oreder__bottom-item">
        <p class="oreder__bottom-name">ТС</p>
        <h3 class="oreder__bottom-value">
          {{ orderById.transport ? orderById.transport.transport.number : "-" }}
        </h3>
      </li>
      <li class="oreder__bottom-item">
        <p class="oreder__bottom-name">Потраченное время в пути</p>
        <h3 class="oreder__bottom-value">
          {{ formatTime(orderById.driving_time) }}
        </h3>
      </li>
      <li class="oreder__bottom-item">
        <p class="oreder__bottom-name">Потраченное время разгрузки</p>
        <h3 class="oreder__bottom-value">
          {{ formatTime(orderById.unloading_time) }}
        </h3>
      </li>
      <li class="oreder__bottom-item">
        <p class="oreder__bottom-name">Потраченное время на отдых</p>
        <h3 class="oreder__bottom-value">
          {{ formatTime(orderById.relaxation_time) }}
        </h3>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    historyPoints: {
      type: Array,
      default: () => [],
    },
    orderById: {
      type: Object,
      default: () => {},
    },
    eventsToggle: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isHistoryLoaded: false,
      // index: 2,
      sliderMax: 0,
      sliderValue: 0,
      // dateShow: false,
      // range: [],
    };
  },
  computed: {
    sliderDate() {
      if (this.isHistoryLoaded) {
        return this.formatDate(this.historyPoints[this.sliderValue].created_at);
      }
      return "";
    },
    sliderSpeed() {
      return this.historyPoints ? this.historyPoints[this.sliderValue].speed : "";
    },
  },
  watch: {
    historyPoints() {
      if (this.historyPoints.length > 0) {
        this.$nextTick(() => {
          this.sliderMax = this.historyPoints.length - 1;
          this.sliderValue = this.sliderMax;
          this.isHistoryLoaded = true;
          this.$emit("loaded", this.sliderValue);
        });
      }
    },
    sliderValue() {
      if (this.isHistoryLoaded) {
        this.$emit("sliderValueChange", this.sliderValue);
      }
    },
  },
  methods: {
    formatTime(timeInSeconds) {
      const result = new Date((timeInSeconds || 0) * 1000).toISOString().substr(11, 8);

      return `${result.substr(3, 2)}ч ${result.substr(6, 2)}мин`;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString("ru-RU", { timeZone: "Asia/Qyzylorda" });
    }
  },
};
</script>

<style scoped>
.order__slider-details {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.order__slider-date {
  text-align: center;
  font-weight: 600;
}
.order__slider-speed {
  text-align: end;
}
.order__slider-end-date {
  text-align: end;
}
.order__slider-label.order__slider-label-top {
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.order__slider-label {
  margin-top: 0.5rem;
  font-family: "Fira Mono", "Lucida Console", "Lucida Sans Typewriter", Consolas, "Courier New",
    Courier, monospace;
  display: grid;
  /* justify-content: space-between; */
  /* grid-template-columns: 9.5rem 1fr 9.5rem; */
  grid-template-columns: 9.5rem auto 9.5rem;
}
.order__slider-input--disabled {
  cursor: not-allowed;
  filter: saturate(0);
}
/* .order__slider-input--disabled::-webkit-slider-thumb {
  visibility: hidden;
} */
.order__slider-input {
  -webkit-appearance: none;
  appearance: none;

  outline: none;
  opacity: 0.7;
  height: 0.5rem; /* Specified height */
  background: #dddde5;
  /* background: #ff0000; */
  border-radius: 999rem;

  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.order__slider-input:hover {
  opacity: 1;
}
.order__slider-input--disabled:hover {
  opacity: 0.7;
}
.order__slider-input.order__slider-input--disabled::-webkit-slider-thumb:hover {
  cursor: not-allowed;
  transform: unset;
}
.order__slider-input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 999rem;
  background: #1e98ff;
  /* background: #1e98ff; */
  cursor: pointer; /* Cursor on hover */
  transition: all 0.15s ease;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d='M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z'/></svg>");
}
.order__slider-input::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}
.order__slider-input::-moz-range-thumb {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 999rem;
  background: #1e98ff;
  /* background: #1e98ff; */
  cursor: pointer; /* Cursor on hover */
}
.order__slider-input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.oreder__bottom-item.order__slider {
  /* flex-grow: 1; */
  /* align-self: stretch; */
  /* margin-left: 0; */
  grid-column-start: 1;
  grid-column-end: 6;
  margin-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
  align-items: stretch;
}
/* .oreder__bottom-item.oreder__bottom-item-date-filter {
  align-self: center;
  justify-content: center;
} */
.oreder__bottom-btn {
  display: flex;
  padding: 9px;
  height: 36px;
  /* background: transparent; */
  border-radius: 8px;
  font-weight: bold;
  line-height: 16px;
  outline: none;
}
.add__calendar {
  position: initial;
  left: initial;
  top: initial;
  z-index: initial;
}
.date-filter__calendar {
  background-color: #f8f8f8;
  border: 1px solid #dedede;
  box-shadow: 0 0 15px 5px #33333355;
  padding: 0.65rem;
  border-radius: 0.5rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
}
.modal-tint-close {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: hsla(0, 0%, 0%, 48%);
}
.oreder__bottom {
  padding: 20px;
  box-sizing: border-box;
  /* height: auto; */
}
.oreder__bottom-name {
  font-size: 12px;
  line-height: 14px;
  color: #acacac;
  margin-bottom: 10px;
}
.oreder__bottom-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: flex;
  margin: 0;
}
.oreder__bottom-items {
  /* height: 100%; */
  /* padding: 0.5rem; */
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* align-items: center;
  align-content: center; */
}
.oreder__bottom-item {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  /* height: 100%; */
  /* flex-basis: 100px; */
  /* max-width: 100px; */
  align-items: flex-start;
  justify-content: center;
}
.oreder__status {
  max-width: 200px;
  width: 200px;
}
.oreder__bottom-text {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #707070;
  margin: 0;
}
.oreder__bottom-text_yellow {
  color: yellow;
}
.working__road {
  margin-top: 15px;
}

.btn {
  height: 35px;
  padding: 6px 0;
  width: 20%;
  float: right;
}
</style>
